<template>
  <v-app style="width: 100vw;">
    <v-main>
      <!-- Sticky Navbar -->
      <v-app-bar class="nav-bar" fixed>
        <v-toolbar-title :class="{ 'mobile-title': isMobile }">{{ $t('title') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <template v-if="isMobile">
          <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        </template>
        <template v-else>
          <v-btn text @click="scrollTo('hero')">{{ $t('home') }}</v-btn>
          <v-btn text @click="scrollTo('caas')">{{ $t('caas') }}</v-btn>
          <v-btn text @click="scrollTo('custom-development')">{{ $t('customDevelopment') }}</v-btn>
          <v-btn icon @click="setLanguage('en')">
            <span class="fi fi-us"></span>
          </v-btn>
          <v-btn icon @click="setLanguage('es')">
            <span class="fi fi-es"></span>
          </v-btn>
        </template>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" app temporary>
        <v-list>
          <v-list-item @click="scrollTo('hero')">{{ $t('home') }}</v-list-item>
          <v-list-item @click="scrollTo('caas')">{{ $t('caas') }}</v-list-item>
          <v-list-item @click="scrollTo('custom-development')">{{ $t('customDevelopment') }}</v-list-item>
          <v-list-item @click="setLanguage('en')">
            <span class="fi fi-us"></span> English
          </v-list-item>
          <v-list-item @click="setLanguage('es')">
            <span class="fi fi-es"></span> Spanish
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <!-- Hero Section -->
      <v-container fluid class="pa-0 hero-container">
        <v-row class="hero-image">
          <v-col cols="12">
            <div :class="['hero-text', { 'mobile-hero-text': isMobile }]">
              {{ $t('heroText') }}
              <br />
              <span class="emphasize">{{ $t('heroEmphasize') }}</span>
            </div>
          </v-col>
          <v-col cols="12" class="hero-cta">
            <v-btn>
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-container fluid class="page-container">
        <v-row class="section">
          <v-col id="caas" cols="12">
            <v-card class="section-card">
              <v-card-title :class="{ 'mobile-card-title': isMobile }">{{ $t('ctaAsServiceTitle') }}</v-card-title>

              <p :class="{ 'mobile-card-subheading': isMobile }">{{ $t('ctaAsServiceSubheading') }}</p>

              <v-card-text style="padding: 0px;">
                <p :class="{ 'mobile-card-text': isMobile }">{{ $t('ctaAsServiceText') }}</p>

                <v-col cols="12">
                  <caasPlans />
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col id="custom-development" cols="12">
            <v-card class="section-card">
              <v-card-title :class="{ 'mobile-card-title': isMobile }">{{ $t('customDevelopmentTitle') }}</v-card-title>

              <p :class="{ 'mobile-card-subheading': isMobile }">{{ $t('customDevelopmentSubheading') }}</p>

              <v-card-text>
                <p :class="{ 'mobile-card-text': isMobile }">{{ $t('customDevelopmentText') }}</p>


                <v-stepper v-model="activeStep" :items="steps" :style="{ width: isMobile ? '100vw' : '95vw' }">

                  <template v-slot:item.1>
                    <v-card flat>
                      <v-card-text>
                        <v-btn color="primary" @click="activeStep = 2">{{ $t('bookVideoCall') }}</v-btn>
                      </v-card-text>
                    </v-card>
                  </template>

                  <template v-slot:item.2>
                    <v-card flat>
                      <v-card-text>
                        <v-item-group mandatory v-model="meetingType" class="text-center">
                          <v-btn @click="(meetingType = 'junior'), (activeStep = 3)">
                            {{ $t('juniorDevMeeting') }}
                          </v-btn>
                          <v-btn @click="(meetingType = 'senior'), (activeStep = 3)">
                            {{ $t('seniorDevMeeting') }}
                          </v-btn>
                        </v-item-group>
                      </v-card-text>
                    </v-card>
                  </template>

                  <template v-slot:item.3>
                    <v-card flat>
                      <v-card-text>
                        <div v-if="meetingType">
                          <p>{{ $t('loadingBookingOptions') }}</p>
                          <v-btn color="primary" @click="openCalendly(meetingType)">{{ $t('openBookingForm') }}</v-btn>
                        </div>
                      </v-card-text>
                    </v-card>
                  </template>

                  <template v-slot:actions>
                    <v-row justify="space-between">
                      <v-col cols="auto">
                        <v-btn @click="activeStep--" style="margin-left: 45%">{{ $t('back') }}</v-btn>
                      </v-col>

                    </v-row>
                  </template>
                </v-stepper>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col id="customer-reviews" cols="12">
            <v-card class="section-card">
              <v-card-title class="card-title">Customer Reviews</v-card-title>
              <p class="card-subheading">What our customer have to say about us.</p>
              <v-card-text style="padding: 0px;">
                <p class="card-text">
                  We trust and firmly believe in the quality of our services and that
                  <strong>customer success</strong>
                  is central to our mission. Here are some reviews from our satisfied customers.
                </p>
                <v-sheet class="review-sheet">
                  <template v-if="isMobile">
                    <div class="mobile-reviews">
                      <v-card v-for="review in shuffledReviews" :key="review.id" class="ma-4"
                        :class="{ 'elevation-10': review.isSelected }" @click="review.isSelected = !review.isSelected"
                        :color="review.isSelected ? 'primary' : 'white'" style="border-radius: 25px;">
                        <v-card-title>
                          <v-row no-gutters style="justify-content: space-between">
                            <v-col cols="10">
                              <div :class="['rating-wrapper', { 'mobile-rating': isMobile }]"
                                style="justify-content: flex-start">
                                <v-rating :model-value="review.stars" length="10" readonly density="compact" color="#f3d129"
                                  size="small"
                                  :item-labels="['' + review.stars + '/10', '', '', '', '', '', '', '', '', '']"></v-rating>
                              </div>
                            </v-col>
                            <v-col cols="auto">
                              <v-btn icon class="clean-icon">
                                <v-icon v-if="review.isSelected">mdi-chevron-up</v-icon>
                                <v-icon v-else>mdi-chevron-down</v-icon>
                              </v-btn>
                            </v-col>
                            <v-col cols="12">
                              {{ review.fullName }} - {{ review.country || "Country TBD" }}
                              <div class="fi" :class="'fi-' + getCountryCode(review.country)"></div>
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-card-subtitle>
                          Referring to:
                          <p style="text-decoration: underline">{{ review.service }}</p>
                        </v-card-subtitle>
                        <v-card-text>"{{ review.commentTitle }}"</v-card-text>
                        <v-card-text v-if="review.isSelected" style="white-space: pre-wrap; word-wrap: break-word">
                          <p><strong>Name:</strong> <u>{{ review.fullName }}</u></p>
                          <p><strong>Company:</strong> <u>{{ review.companyName }}</u></p>
                          <p><strong>Industry:</strong> <u>{{ review.industry }}</u></p>
                          <p><strong>More Information:</strong> {{ review.comment }}</p>
                        </v-card-text>
                      </v-card>
                    </div>
                  </template>
                  <template v-else>
                    <v-slide-group v-model="model" class="pa-4" selected-class="bg-primary" show-arrows>
                      <v-slide-group-item v-for="review in shuffledReviews" :key="review.id"
                        v-slot="{ isSelected, toggle, selectedClass }">
                        <v-card :class="['ma-4', selectedClass, { 'elevation-10': isSelected }]" @click="toggle"
                          :color="isSelected ? 'primary' : 'white'" style="border-radius: 25px; max-width: 500px">
                          <v-card-title>
                            <v-row no-gutters style="justify-content: space-between">
                              <v-col cols="10">
                                <div style="justify-content: flex-start">
                                  <v-rating :model-value="review.stars" length="10" readonly dense color="#f3d129"
                                    size="medium"
                                    :item-labels="['' + review.stars + '/10', '', '', '', '', '', '', '', '', '']"></v-rating>
                                </div>
                              </v-col>
                              <v-col cols="auto">
                                <v-btn icon class="clean-icon">
                                  <v-icon v-if="isSelected">mdi-chevron-up</v-icon>
                                  <v-icon v-else>mdi-chevron-down</v-icon>
                                </v-btn>
                              </v-col>
                              <v-col cols="12">
                                {{ review.fullName }} - {{ review.country || "Country TBD" }}
                                <div class="fi" :class="'fi-' + getCountryCode(review.country)"></div>
                              </v-col>
                            </v-row>
                          </v-card-title>
                          <v-card-subtitle>
                            Referring to:
                            <p style="text-decoration: underline">{{ review.service }}</p>
                          </v-card-subtitle>
                          <v-card-text>"{{ review.commentTitle }}"</v-card-text>
                          <v-card-text v-if="isSelected" style="white-space: pre-wrap; word-wrap: break-word">
                            <p><strong>Name:</strong> <u>{{ review.fullName }}</u></p>
                            <p><strong>Company:</strong> <u>{{ review.companyName }}</u></p>
                            <p><strong>Industry:</strong> <u>{{ review.industry }}</u></p>
                            <p><strong>More Information:</strong> {{ review.comment }}</p>
                          </v-card-text>
                        </v-card>
                      </v-slide-group-item>
                    </v-slide-group>
                  </template>
                </v-sheet>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { useI18n } from 'vue-i18n';
import caasPlans from "./components/caasPlans.vue";

export default {
  name: "App",
  components: {
    caasPlans,
  },
  setup() {
    const isMobile = ref(window.innerWidth <= 768 || window.matchMedia("(display-mode: standalone)").matches);
    const updateIsMobile = () => {
      isMobile.value = window.innerWidth <= 768 || window.matchMedia("(display-mode: standalone)").matches;
    };

    const drawer = ref(false);
    const caas = ref(null);
    const customDevelopment = ref(null);
    const meetingType = ref(null);
    const activeStep = ref(1);
    const steps = [
      {
        title: "Book a Video Call",
      },
      {
        title: "How big is your project?",
      },
      {
        title: "Select adate and time",
      },
    ];
    const { locale } = useI18n();
    const setLanguage = (lang) => {
      locale.value = lang;
      localStorage.setItem('language', lang);
    };
    const scrollTo = (sectionId) => {
      console.log("Attempting to scroll to:", sectionId); // Check if this logs
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };

    const customerReviews = ref([
      {
        stars: 8,
        service: "CTO as a Service",
        commentTitle: "Helpful service, helps us transition do in house CTO.",
        fullName: "Michael Johnson",
        country: "United States",
        companyName: "SSL Inc.",
        industry: "E-commerce",
        comment:
          "The service provided a good foundation for our tech strategy, but as we scaled, the need for a more dedicated presence became apparent.",
      },
      {
        stars: 10,
        service: "CTO as a Service",
        commentTitle: "Good insights, we work side by side.",
        fullName: "David Smith",
        country: "United States",
        companyName: "Robinson Apparel",
        industry: "E-commerce",
        comment:
          "We appreciated the strategic insights offered,  we apreriate the fact that they take part on meetings from other areas to deeply understand what we need and build accordingly to that.",
      },
      {
        stars: 8,
        service: "CTO as a Service",
        commentTitle: "Service was competent but we outgrew the part-time arrangement and hired a full-time CTO.",
        fullName: "Christopher Brown",
        country: "United States",
        companyName: "Nails and nails inc.",
        industry: "E-commerce",
        comment:
          "The part-time consultancy was effective initially, providing competent tech leadership that helped shape our early decisions. Nonetheless, our expanding needs eventually highlighted the constraints of not having a full-time CTO.",
      },
      {
        stars: 9,
        service: "CTO as a Service",
        commentTitle: "They became a crutial part of our team.",
        fullName: "Jessica Miller",
        country: "United States",
        companyName: "APL",
        industry: "E-commerce",
        comment: "The advice we received was useful and positively influenced our technology path.",
      },
      {
        stars: 10,
        service: "CTO as a Service",
        commentTitle: "Useful , but our expanding operation necessitated a full-time CTO.",
        fullName: "Sarah Wilson",
        country: "United States",
        companyName: "Jones LLC",
        industry: "Construction",
        comment:
          "Initially, the external CTO service was quite effective, setting up a strong technical foundation. However, as our business expanded, the arrangement felt somewhat restrictive, pushing us to seek a full-time internal solution.",
      },
      {
        stars: 10,
        service: "CTO as a Service",
        commentTitle: "Very effective, reliable team, we can count on them",
        fullName: "James Moore",
        country: "United States",
        companyName: "Bright Builders",
        industry: "Construction",
        comment: "Honestly this is exaclty what we needed.",
      },
      {
        stars: 10,
        service: "CTO as a Service",
        commentTitle: "Provided valuable service, a real CTO, able to manage resources and prioritize.",
        fullName: "Robert Taylor",
        country: "United States",
        companyName: "Fut Vis",
        industry: "Small Retail",
        comment:
          "We take confort in having them overseeing the operation and helping every area buil tools that help in efficiency.",
      },
      {
        stars: 10,
        service: "Custom Development",
        commentTitle: "Creative and solution-oriented, effectively handled our complex technical needs.",
        fullName: "Juan Carlos Ruiz",
        country: "Mexico",
        companyName: "Accesorios DDN",
        industry: "E-commerce",
        comment:
          "The team managed to deliver creative solutions that were crucial for our project's success. While the engagement was mostly positive, as we expanded, the need for a full-time technical lead began to show.",
      },
      {
        stars: 9,
        service: "Custom Development",
        commentTitle: "Problem solvers who helped us navigate tricky development hurdles with ease.",
        fullName: "Diego Martinez",
        country: "Mexico",
        companyName: "PyN Accesorios",
        industry: "Small Retail",
        comment:
          "Their ability to address complex technical challenges was impressive and pivotal for our project. The service was extremely beneficial, though as we grew, the occasional need for more consistent in-person interactions was felt.",
      },
      {
        stars: 10,
        service: "Custom Development",
        commentTitle: "Their innovative approaches helped overcome technical challenges in our projects.",
        fullName: "Ivan Dimitrov",
        country: "Bulgaria",
        companyName: "Ivan Dimitrov",
        industry: "Lawyer Firm",
        comment:
          "The innovative strategies employed were key in navigating our technical landscape. While the service was predominantly positive, the growing demands of our projects eventually called for a more permanent technical leader, so we extended their contract indefinitely",
      },
      {
        stars: 9,
        service: "Custom Development",
        commentTitle: "Their innovative approaches helped overcome technical challenges in our projects.",
        fullName: "Juan Palazuelo",
        country: "Chile",
        companyName: "Not Fashion",
        industry: "E-commerce",
        comment:
          "The innovative strategies employed were key in navigating our technical landscape. While the service was predominantly positive, the growing demands of our projects eventually called for a more permanent technical leader.",
      },
      {
        stars: 9,
        service: "CTO as a Service",
        commentTitle: "Exceptional service that perfectly matched our growth trajectory.",
        fullName: "Felipe González",
        country: "Chile",
        companyName: "Guerra y Guerra SA",
        industry: "Construction",
        comment:
          "The CTO as a Service was instrumental in aligning our technology with our business goals. As we scaled, the service seamlessly adapted to our needs, providing exceptional value and expertise.",
      },
      {
        stars: 8,
        service: "CTO as a Service",
        commentTitle: "Outstanding strategic leadership that drove our success.",
        fullName: "Laura Fernández",
        country: "Chile",
        companyName: "InnovativeTech Ltda.",
        industry: "Small Retail",
        comment:
          "Their strategic leadership was pivotal in our growth phase, offering insights that drove our success. The flexibility and expertise provided were beyond our expectations, making the service highly valuable.",
      },
      {
        stars: 10,
        service: "CTO as a Service",
        commentTitle: "A game-changer for our technological advancements.",
        fullName: "Carlos Rojas",
        country: "Chile",
        companyName: "JN y asociados",
        industry: "Lawyer Firm",
        comment:
          "The service was a game-changer for us, driving significant technological advancements. The expert guidance and seamless integration with our team ensured we stayed ahead in our industry.",
      },
      {
        stars: 10,
        service: "CTO as a Service",
        commentTitle: "Transformed our tech strategy with exceptional results.",
        fullName: "María Valdés",
        country: "Chile",
        companyName: "Insumos de Belleza Valdes SpA",
        industry: "E-commerce",
        comment:
          "Their expertise transformed our tech strategy, resulting in exceptional outcomes. The service was highly effective in meeting our evolving needs, making a significant impact on our business.",
      },
      {
        stars: 9,
        service: "Custom Development",
        commentTitle: "Innovative solutions that met our complex development needs.",
        fullName: "Janne Virtanen",
        country: "Finland",
        companyName: "New Solutions Oy",
        industry: "E-commerce",
        comment:
          "The custom development services provided were highly innovative and met our complex technical needs. While the overall experience was positive, we occasionally felt the need for more frequent updates.",
      },
      {
        stars: 8,
        service: "CTO as a Service",
        commentTitle: "Outstanding service that significantly boosted our growth.",
        fullName: "Anna Mäkinen",
        country: "Finland",
        companyName: "KimPoint Oy",
        industry: "Construction",
        comment:
          "The CTO as a Service was outstanding, significantly boosting our growth. Their expertise and strategic insights were invaluable, seamlessly integrating with our team and driving us forward.",
      },
      {
        stars: 9,
        service: "CTO as a Service",
        commentTitle: "Exceptional leadership that guided our tech strategy.",
        fullName: "Mikko Nieminen",
        country: "Finland",
        companyName: "Innovatech Oy",
        industry: "Small Retail",
        comment:
          "Their leadership was exceptional, guiding our tech strategy with precision. The service provided invaluable insights and was highly adaptable to our evolving needs, making a significant impact on our success.",
      },
      {
        stars: 9,
        service: "CTO as a Service",
        commentTitle: "Excellent strategic guidance that enhanced our tech capabilities.",
        fullName: "Carlos López",
        country: "Colombia",
        companyName: "Tecnologías Avanzadas SAS",
        industry: "Lawyer Firm",
        comment:
          "The strategic guidance provided was excellent, significantly enhancing our tech capabilities. Their expertise and seamless integration with our team were invaluable, driving our growth and success.",
      },
      {
        stars: 9,
        service: "CTO as a Service",
        commentTitle: "Top-notch service that perfectly aligned with our business goals.",
        fullName: "Laura García",
        country: "Colombia",
        companyName: "Papeles y Colores LTDA",
        industry: "E-commerce",
        comment:
          "The service was top-notch, perfectly aligning with our business goals. The expert advice and leadership provided were crucial in navigating our technological landscape, making a significant positive impact.",
      },
      {
        stars: 9,
        service: "CTO as a Service",
        commentTitle: "Exceptional service that drove our technological innovation.",
        fullName: "Juan Pérez",
        country: "Colombia",
        companyName: "Ingenieria JP SAS",
        industry: "Construction",
        comment:
          "The service was exceptional, driving our technological innovation. Their strategic insights and expert guidance were key to our success, seamlessly integrating with our team and pushing us forward.",
      },
      {
        stars: 10,
        service: "Custom Development",
        commentTitle: "Innovative development solutions that exceeded our expectations.",
        fullName: "María Rodríguez",
        country: "Colombia",
        companyName: "Desarrollos Creativos LTDA",
        industry: "Small Retail",
        comment:
          "The custom development solutions provided were innovative and exceeded our expectations. Their ability to handle complex technical challenges was impressive, resulting in successful project outcomes.",
      },
      {
        stars: 10,
        service: "CTO as a Service",
        commentTitle: "Highly beneficial service with minor areas for improvement.",
        fullName: "Carlos Martínez",
        country: "Spain",
        companyName: "Soluciones Empresariales SL",
        industry: "Lawyer Firm",
        comment:
          "The service was highly beneficial and provided significant strategic value. While the overall experience was positive, there were a few areas where more frequent interaction could have enhanced the outcome.",
      },
      {
        stars: 10,
        service: "CTO as a Service",
        commentTitle: "Exceptional strategic leadership that drove our success.",
        fullName: "Ana García",
        country: "Spain",
        companyName: "Repuestos Online SL",
        industry: "E-commerce",
        comment:
          "Their exceptional strategic leadership was crucial in driving our success. The expertise and insights provided were invaluable, seamlessly aligning with our business goals and facilitating significant growth.",
      },
      {
        stars: 9,
        service: "CTO as a Service",
        commentTitle: "Outstanding service that boosted our technological capabilities.",
        fullName: "Luis Hernández",
        country: "Spain",
        companyName: "Tecnologías Avanzadas SL",
        industry: "Construction",
        comment:
          "The service was outstanding, significantly boosting our technological capabilities. Their strategic guidance and expert support were instrumental in our growth, providing exceptional value throughout the engagement.",
      },
      {
        stars: 7,
        service: "CTO as a Service",
        commentTitle: "Transformative service that perfectly aligned with our vision.",
        fullName: "María Sánchez",
        country: "Spain",
        companyName: "OffiTech SA",
        industry: "Small Retail",
        comment:
          "The service was transformative, perfectly aligning with our vision and driving substantial progress. Their strategic insights and seamless integration with our team were key to our success, making a significant impact on our technological landscape.",
      },
    ]);

    const model = ref(null);

    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
        [array[i], array[j]] = [array[j], array[i]]; // swap elements
      }
      return array;
    };

    // Computed property to shuffle reviews
    const shuffledReviews = computed(() => {
      return shuffleArray([...customerReviews.value]);
    });

    const openCalendly = (type) => {
      const url =
        type === "senior"
          ? "https://calendly.com/ss-sbs-info/30min"
          : "https://calendly.com/ss-sbs-info/30min";

      console.log(`Opening Calendly for: ${type}`);
      console.log('Attempting to open Calendly in a new tab...');

      window.open(url, '_blank');
      return false;
    };
    const getCountryCode = (countryName) => {
      const countryCodes = {
        "United States": "us",
        Mexico: "mx",
        Bulgaria: "bg",
        Chile: "cl",
        Finland: "fi",
        Colombia: "co",
        Spain: "es",

        // Add more countries as needed
      };
      return countryCodes[countryName] || "unknown";
    };
    onMounted(() => {
      window.addEventListener("resize", updateIsMobile);
      updateIsMobile(); // Initial update
      shuffledReviews.value = shuffleArray([...customerReviews.value]);
      model.value = null; // Set to first index or null if array is empty
    });

    onUnmounted(() => {
      window.removeEventListener("resize", updateIsMobile);
    });
    return {
      // Add your variables here
      drawer,
      isMobile,
      caas,
      customDevelopment,
      scrollTo,
      openCalendly,
      steps,
      meetingType,
      activeStep,
      shuffledReviews,
      model,
      getCountryCode,
      setLanguage
    };
  },
};
</script>

<style scoped>
.nav-bar {
  background-color: #000000 !important;
  box-shadow: 10px !important;
  color: white !important;
}

.hero-container {
  padding: 0;
  background: linear-gradient(90deg, #000000, #3533cd);
  color: white;
}

.hero-image {
  /* background: linear-gradient(90deg, #000000, #3533cd); */
  /* background: radial-gradient(circle, #3533cd, #000000);
   */
  background: radial-gradient(circle 450px at center, #3533cd, #000000);

  display: flex;
  align-items: center;
  height: 100vh;
  padding: 20px;
}

.hero-text {
  font-family: "Courier New", Courier, monospace;
  font-size: 52px;
  font-variant-ligatures: none;
  text-align: center;
  font-weight: 2000;
  color: #ffffff;
}

.emphasize {
  background-color: #ffffff;
  animation: emphasizeAnimation 1s ease-out;
  font-weight: bold;
  color: black;
}

@keyframes emphasizeAnimation {
  from {
    background-color: transparent;
    transform: scale(1);
  }

  to {
    background-color: #ffffff;
    transform: scale(1.2);
  }
}

.hero-cta {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: white;
}

.section {
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.page-container {
  background: linear-gradient(180deg, #000000, #3533cd) !important;
}

.section-card {
  margin: 10px !important;
  padding: 20px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  background: linear-gradient(90deg, #cecece, #3533cd) !important;
  color: rgb(0, 0, 0) !important;
  border-radius: 25px !important;
}

.card-title {
  font-size: 2rem;
  font-weight: 800;
  color: #000000;
}

.card-subheading {
  color: #555;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.card-text {
  font-size: 1.1rem;
  line-height: 1.6;
}

.review-sheet {
  border-radius: 25px;
  background: linear-gradient(90deg, #cecece, #3533cd) !important;
}

.fi {
  margin-right: 8px;
}

.clean-icon {
  background-color: transparent !important;
  box-shadow: none !important;
  margin: 0;
  padding: 0;
}

.mobile-title {
  font-size: 1.5rem;
}

.mobile-hero-text {
  font-size: 32px;
}

.mobile-card-title {
  font-size: 1.5rem;
}

.mobile-card-subheading {
  font-size: 1rem;
}

.mobile-card-text {
  font-size: 0.9rem;
}

.mobile-reviews {
  max-height: 80vh; /* Set a fixed height for the container */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 0 10px; /* Optional: add padding to the sides */
  display: flex;
  flex-direction: column;
}

.mobile-reviews .v-card {
  max-width: 100%;
  padding: 8px; /* Smaller padding for mobile */
  margin: 8px 0; /* Space between cards */
  flex: 0 0 auto;
}

.mobile-rating .v-rating {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  /* Adjust the size as needed */
}

.mobile-rating .v-rating .v-rating__item {
  margin-right: 2px !important;
}

/* Reduce spacing between stars */
</style>
