import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import es from './locales/es.json';
import { loadFonts } from './plugins/webfontloader';

import App from '@/App.vue';
import vuetify from '@/plugins/vuetify'; 
import '@mdi/font/css/materialdesignicons.min.css';
import 'flag-icons/css/flag-icons.min.css';

loadFonts();

const messages = {
  en,
  es,
};

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('language') || 'en',
  fallbackLocale: 'en',
  messages,
});

const app = createApp(App);
app.use(i18n); // Use i18n as a plugin
app.use(vuetify);  // Use vuetify as a plugin
app.mount('#app');