import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { es } from 'vuetify/locale';

// const getCssVariable = (name) => getComputedStyle(document.documentElement).getPropertyValue(name).trim();



const vuetify = createVuetify({
  locale: {
    locale: 'es', // Set the default locale key
    messages: {
      es // Load Spanish locale messages
    }
  },
  components,
  directives,
  icons: {
    iconfont: 'mdi',
  },

  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        primary: 'blue',
        secondary: 'green',
        // Other color configurations
      },
      // dark theme setup if needed
    }
  },
});
export default vuetify;