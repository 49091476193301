<template>
  <div class="pricing-table">
    <v-row>
      <v-col cols="12" sm="4" v-for="plan in plans" :key="plan.title">
        <v-card class="pa-3 elevation-2" style="border-radius: 25px;">
          <v-card-title>{{ plan.title }}</v-card-title>
          <v-card-subtitle>${{ plan.price }} USD</v-card-subtitle>
          <v-card-text>
            <ul>
              <li v-for="feature in plan.features" :key="feature">{{ feature }}</li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary">Choose Plan</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { ref } from "vue";

const basicFeatures = [
  "Monthly technology strategy review",
  "Basic IT infrastructure oversight",
  "Email support",
  "Access to standard industry practices documentation",
  "4 hours of consultancy per month",
];

const standardFeatures = [
  ...basicFeatures,
  "Bi-weekly technology strategy sessions",
  "IT project management",
  "Priority email and phone support",
  "10 hours of consultancy per month",
];

const premiumFeatures = [
  ...standardFeatures,
  "Weekly technology strategy sessions",
  "Dedicated CTO services for project oversight",
  "Real-time chat support",
  "Unlimited consultancy hours",
];

const plans = ref([
  {
    title: "Basic",
    price: 500,
    features: basicFeatures,
  },
  {
    title: "Standard",
    price: 1000,
    features: standardFeatures,
  },
  {
    title: "Premium",
    price: 2000,
    features: premiumFeatures,
  },
]);
</script>

<style scoped>
.pricing-table {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
}
</style>
